import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"

import React from "react"

import { createLoadingKeys } from "src/lib/loading"
import { loads } from "src/channel/utils"
import { FormFields } from "src/lib/form-fields"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { chatbot_AgentName, ChatbotAdminService } from "src/api"
import { reportError } from "src/lib/report"
import { MixpanelProperties } from "src/analytics/constants/properties"
import { trackModuleEvent } from "src/analytics/helpers/mixpanel_tracking"

import { Channel } from "src/channel"
import {
    IAgentsList,
    IConfigAgentsItemProps,
} from "src/modals/configuring-agents-detail/types/configAgentsItemProps"

const agentLabels = {
    qa_agent: "Q&A",
    classification: "Classification",
    dynamic_form: "Dynamic Form",
}

export class ChatbotConfiguringStore {
    static Context = React.createContext<ChatbotConfiguringStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    hasError: boolean = false
    isLoading = false

    private _configurableAgentsLists: IAgentsList[] = []
    private _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    private _accessType: string = "WRITE"

    form = new FormFields<IConfigAgentsItemProps>({
        access_group_id: DEFAULT_ACCESS_GROUP.id,
        access_type: "",
        agent_config_id: undefined,
        agent_name: "",
        avatar_url: "",
        header_headline_en: "",
        header_headline_sv: "",
        header_headline_fi: "",
        header_description_en: "",
        header_description_sv: "",
        header_description_fi: "",
        welcome_message_en: "",
        welcome_message_sv: "",
        welcome_message_fi: "",
        name: "",
        segment_ids: [],
    })

    constructor() {
        makeAutoObservable(this)
    }
    get isReadOnly() {
        return this._accessType === "READ"
    }

    get configurableAgentsLists() {
        return this._configurableAgentsLists
    }

    get accessGroupId() {
        return this._accessGroupId
    }

    private setAccessType(accessType: string) {
        this._accessType = accessType
    }

    private setAccessGroupId(id: number) {
        this._accessGroupId = id
    }

    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading)

    @loads(() => ChatbotConfiguringStore.LoadingKeys.init)
    async init(id: string | undefined, accessGroupId: number) {
        await this.getConfigurableAgentsList()
        try {
            this.setAccessGroupId(accessGroupId)
            if (id !== undefined) {
                const response =
                    await ChatbotAdminService.getV1AdminChatbotAgentConfig({
                        configId: id,
                    })

                this.form.init({
                    access_group_id:
                        response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                    access_type: response?.access_type ?? "",
                    agent_config_id: response?.agent_config_id ?? undefined,
                    agent_name: response?.agent_name ?? "",
                    avatar_url: response?.config?.avatar_url ?? "",
                    header_headline_en:
                        response?.config?.header_headline?.en ?? "",
                    header_headline_sv:
                        response?.config?.header_headline?.sv ?? "",
                    header_headline_fi:
                        response?.config?.header_headline?.fi ?? "",
                    header_description_en:
                        response?.config?.header_description?.en ?? "",
                    header_description_sv:
                        response?.config?.header_description?.sv ?? "",
                    header_description_fi:
                        response?.config?.header_description?.fi ?? "",
                    welcome_message_en:
                        response?.config?.welcome_message?.en ?? "",
                    welcome_message_sv:
                        response?.config?.welcome_message?.en ?? "",
                    welcome_message_fi:
                        response?.config?.welcome_message?.fi ?? "",
                    name: response?.name ?? "",
                    segment_ids: response?.segment_ids ?? [],
                })

                this.setAccessGroupId(
                    response?.access_group_id ?? DEFAULT_ACCESS_GROUP.id,
                )

                if (response?.access_type !== undefined) {
                    this.setAccessType(response?.access_type)
                }
            }
        } catch (error) {
            reportError(t`configuring-agents-detail.load-data-fail`, error)
            this.setAccessType("READ")
        }

        return Promise.resolve()
    }

    @loads(() => ChatbotConfiguringStore.LoadingKeys.submit)
    async submit() {
        try {
            await this.form.catchErrors(async () => {
                const isUpdate = this.form.get("agent_config_id") !== undefined
                let configId: string | number = isUpdate
                    ? `${this.form.get("agent_config_id")}`
                    : ""

                const commonConfig = {
                    agent_name: this.form.get(
                        "agent_name",
                    ) as chatbot_AgentName,
                    name: this.form.get("name"),
                    config: {
                        welcome_message: {
                            sv: this.form.get("welcome_message_sv"),
                            en: this.form.get("welcome_message_en"),
                            fi: this.form.get("welcome_message_fi"),
                        },
                        avatar_url: this.form.get("avatar_url"),
                        header_headline: {
                            sv: this.form.get("header_headline_sv"),
                            en: this.form.get("header_headline_en"),
                            fi: this.form.get("header_headline_fi"),
                        },
                        header_description: {
                            sv: this.form.get("header_description_sv"),
                            en: this.form.get("header_description_en"),
                            fi: this.form.get("header_description_fi"),
                        },
                    },
                }

                let response
                if (isUpdate) {
                    await ChatbotAdminService.putV1AdminChatbotAgentConfig({
                        configId,
                        request: {
                            ...commonConfig,
                            agent_config_id: this.form.get("agent_config_id"),
                            access_group_id: this.form.get("access_group_id"),
                        },
                    })
                } else {
                    response =
                        await ChatbotAdminService.postV1AdminChatbotAgentConfig(
                            {
                                request: {
                                    ...commonConfig,
                                    access_group_id: this.accessGroupId,
                                },
                            },
                        )
                    configId =
                        response.agent_config_id !== undefined
                            ? `${response.agent_config_id}`
                            : ""
                }

                if (configId !== undefined) {
                    await ChatbotAdminService.putV1AdminChatbotAgentConfigPublish(
                        {
                            configId,
                            request: {
                                published_in: this.form.get("segment_ids"),
                            },
                        },
                    )
                }

                Channel.send({
                    name: "repository/updated",
                    payload: {
                        repository: "agents-items",
                        action: isUpdate ? "update" : "create",
                    },
                })
            })
        } catch (error) {
            reportError(t`configuring-agents-detail.save-item-fail`, error)
        } finally {
            this.setIsLoading(false)
        }
    }

    async getConfigurableAgentsList() {
        let responseData = []
        const response =
            await ChatbotAdminService.getV1AdminChatbotAgentConfigConfigurableAgents()
        responseData = response?.agents ?? []

        this._configurableAgentsLists =
            responseData?.map((val) => {
                return { id: val, label: agentLabels[val] }
            }) ?? []
    }

    async handleSubmit() {
        this.form.validateRequiredFields([
            { field: "name" },
            { field: "agent_name" },
            { field: "avatar_url" },
            { field: "header_headline_en" },
            { field: "header_headline_sv" },
            { field: "header_description_en" },
            { field: "header_description_sv" },
            { field: "welcome_message_en" },
            { field: "welcome_message_sv" },
        ])

        if (this.form.hasErrors() === true) {
            this.setIsLoading(false)
            return false
        }

        trackModuleEvent("Chatbot Agent Items | Save", {
            [MixpanelProperties.ItemName]: this.form.data.agent_name,
            [MixpanelProperties.AccessGroupID]: this.form.data.access_group_id,
        })
        await this.submit()
        return true
    }
}
