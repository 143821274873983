import { t } from "@lingui/macro"
import React from "react"

import { createLoadingKeys } from "src/lib/loading"
import { DEFAULT_ACCESS_GROUP } from "src/config"
import { Pagination } from "src/lib/pagination"
import { chatbot_AgentConfigForList, ChatbotAdminService } from "src/api"
import { IAgentConfigItem } from "src/views/configuring-agents-persona/types"
import { loads } from "src/channel/utils"
import { IAdvanceQueryModel } from "src/types/data-grid-pro"
import { CustomDataHelper } from "src/api/_custom/services/DataHelper"
import { reportError } from "src/lib/report"

import { Channel } from "src/channel"

export class ConfiguringAgentsPersonaStore implements IDisposable {
    static Context = React.createContext<ConfiguringAgentsPersonaStore | null>(
        null,
    )
    static LoadingKeys = createLoadingKeys("init", "loading", "getAssignees")

    private repositoryUpdatesListenerDisposer?: () => void

    _accessGroupId: number = DEFAULT_ACCESS_GROUP.id
    _segments: number[] = []

    configuringAgentsItems = new Pagination(
        async (query) => {
            const response =
                await ChatbotAdminService.postV1AdminChatbotAgentConfigList({
                    request: {
                        page_number: query.page,
                        page_size: query.pageSize,
                        filter: query.advanceQuery,
                        ...CustomDataHelper.PrepareVisibilityData(this),
                    },
                })
            const items: IAgentConfigItem[] =
                response?.configs?.map((item: chatbot_AgentConfigForList) => ({
                    id: item.agent_config_id ?? 0,
                    access_group_id: item.access_group_id ?? 0,
                    access_type: item.access_type ?? "",
                    agent_config_id: item.agent_config_id ?? 0,
                    agent_name: item.agent_name ?? "",
                    config: item.config ?? {},
                    created_at: item.created_at ?? "",
                    name: item.name ?? "",
                    segment_ids: item.segment_ids ?? [],
                    segment_names: item.segment_names ?? [],
                    updated_at: item.updated_at ?? "",
                })) ?? []

            return {
                items,
                count: response?.configs?.length ?? 0,
            }
        },
        { loadingKey: ConfiguringAgentsPersonaStore.LoadingKeys.loading },
    )

    dispose() {
        this.repositoryUpdatesListenerDisposer?.()
    }

    get segments() {
        return this._segments
    }

    private setSegments(segments: number[]) {
        this._segments = segments
    }

    async loadSegments(segments: number[]) {
        await this.setSegments(segments)
        await this.configuringAgentsItems.loadInitialPage()
    }

    private setAccessGroupId(accessGroupId: number) {
        this._accessGroupId = accessGroupId
    }

    @loads(() => ConfiguringAgentsPersonaStore.LoadingKeys.init)
    async init(accessGroupId: number, advanceQuery?: IAdvanceQueryModel) {
        this.listenToMessageRepositoryUpdated()
        this.setAccessGroupId(accessGroupId)
        await this.configuringAgentsItems.loadInitialPage(advanceQuery)
    }

    async query(advanceQuery: IAdvanceQueryModel) {
        await this.configuringAgentsItems.loadAdvanceQuery(advanceQuery)
    }

    private listenToMessageRepositoryUpdated() {
        this.repositoryUpdatesListenerDisposer = Channel.addListener(
            async (event) => {
                if (
                    event.name === "repository/updated" &&
                    event.payload.repository === "agents-items"
                ) {
                    await this.configuringAgentsItems.reload()
                }
            },
        )
    }

    deleteItem = async (configId: string) => {
        try {
            await ChatbotAdminService.deleteV1AdminChatbotAgentConfig({
                configId,
            })
        } catch (error) {
            reportError(
                t`configuring-agents-view.store.failed-to-delete-item`,
                error,
            )
        } finally {
            await this.configuringAgentsItems.reload()
        }
    }
}
