import { t } from "@lingui/macro"
import { useMediaQuery, useTheme } from "@mui/material"
import { observer } from "mobx-react"
import React, { useCallback, useRef } from "react"

import { FileInputButton } from "src/components/AssetAttachmentsField/FileInputButton"
import { Icon } from "src/components/icons"

import { UploadCloud56 } from "src/components/icons/UploadCloud56"

const DEFAULT_ACCEPTED_FILE_TYPES = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
]

interface IDimensions {
    width: number
    height: number
}

interface IProps {
    onChange: (files: IFile[]) => void
    aspectRatio?: string
    disabled?: boolean
    maxFileSize?: number
    preferredFormat?: string
    acceptedFormats?: string[]
    removeTypes?: string[]
    dimensions?: IDimensions
    setErrorMessage?: (message: string) => void
}

export const UploadImageField = observer((props: IProps) => {
    const {
        onChange,
        aspectRatio = "1/1",
        disabled = false,
        maxFileSize = 8 * 1024 * 1024, // 8 MB
        acceptedFormats = DEFAULT_ACCEPTED_FILE_TYPES,
        dimensions,
        setErrorMessage,
    } = props

    const inputRef = useRef<HTMLInputElement | null>(null)
    const createdObjectUrls = useRef<string[]>([])
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const validateImage = useCallback(
        // eslint-disable-next-line require-await
        async (file: File): Promise<boolean> => {
            // Validate file format
            if (!acceptedFormats.includes(file.type)) {
                if (setErrorMessage !== undefined) {
                    setErrorMessage(
                        t({
                            id: "asset-library-picker.text.invalid-file-format",
                            values: {
                                value: `${acceptedFormats
                                    .map((format) => format.split("/")[1])
                                    .join(", ")}`,
                            },
                        }),
                    )
                }

                return false
            }

            // Validate file size
            if (file.size > maxFileSize) {
                if (setErrorMessage !== undefined) {
                    setErrorMessage(
                        t({
                            id: "asset-library-picker.text.file-size-exceeds",
                            values: {
                                value: `${maxFileSize / (1024 * 1024)}`,
                            },
                        }),
                    )
                }
                return false
            }

            // Validate aspect ratio (skip for SVG)
            if (file.type !== "image/svg+xml") {
                return new Promise((resolve) => {
                    const img = new Image()
                    img.src = URL.createObjectURL(file)
                    img.onload = () => {
                        let isValid = true
                        if (dimensions !== undefined) {
                            isValid =
                                img.width === dimensions.width &&
                                img.height === dimensions.height
                            if (!isValid && setErrorMessage !== undefined) {
                                setErrorMessage(
                                    t({
                                        id: "asset-library-picker.text.image-dimensions",
                                        values: {
                                            width: dimensions.width,
                                            height: dimensions.height,
                                        },
                                    }),
                                )
                            }
                        } else {
                            const actualRatio = img.width / img.height
                            const [targetWidth, targetHeight] = aspectRatio
                                .split("/")
                                .map(Number)
                            const targetRatio = targetWidth / targetHeight
                            isValid =
                                Math.abs(actualRatio - targetRatio) <= 0.01
                            if (!isValid && setErrorMessage !== undefined) {
                                setErrorMessage(
                                    t({
                                        id: "asset-library-picker.text.image-aspect-ratio",
                                        values: { value: aspectRatio },
                                    }),
                                )
                            }
                        }
                        URL.revokeObjectURL(img.src)
                        resolve(isValid)
                    }
                })
            }

            return true
        },
        [
            acceptedFormats,
            maxFileSize,
            setErrorMessage,
            dimensions,
            aspectRatio,
        ],
    )

    const handleChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (
                event?.target?.files !== null &&
                event.target.files.length > 0
            ) {
                const file = event.target.files[0]
                const isValid = await validateImage(file)

                if (isValid) {
                    const url = URL.createObjectURL(file)
                    createdObjectUrls.current.push(url)
                    onChange([{ file, url }])
                }
            }
            event.target.value = ""
        },
        [validateImage, onChange],
    )

    return (
        <>
            <FileInputButton
                type="button"
                title={t`attachment-field-component.image-button`}
                disabled={disabled}
                onClick={() => inputRef.current?.click()}
                sx={{
                    aspectRatio:
                        dimensions !== undefined
                            ? `${dimensions.width}/${dimensions.height}`
                            : aspectRatio,
                    height:
                        aspectRatio == null
                            ? { xs: "64px", sm: "128px" }
                            : "auto",
                }}
            >
                <Icon icon={<UploadCloud56 />} size={smallScreen ? 32 : 56} />
            </FileInputButton>
            <input
                ref={inputRef}
                style={{ display: "none" }}
                type="file"
                onChange={handleChange}
                accept={acceptedFormats.join(",")}
                multiple
            />
        </>
    )
})
