/* eslint-disable import/no-relative-parent-imports */
import { IFieldsVerificationDataSet } from "../types/fieldsVerficationDataset"
import { FormTypeDropdownKeys } from "../types/formTypeDropdownValues"

export const fieldsVerificationDataSet: IFieldsVerificationDataSet = {
    dropdownValue: {
        verificationType: [],
    },
    inputList: {
        verificationType: [
            FormTypeDropdownKeys.RadioButton,
            FormTypeDropdownKeys.Checkbox,
            FormTypeDropdownKeys.Dropdown,
        ],
    },
    label: {
        verificationType: [],
    },
    helpingText: {
        verificationType: [],
    },
}
