import { FormControl, Input, InputAdornment, InputLabel } from "@mui/material"
import { AddCommentRounded } from "@material-ui/icons"
import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import { IHelpingInputTextProps } from "src/modals/contact-forms-form-builder/types/formFieldsSection"

export const HelpingInputText = observer((props: IHelpingInputTextProps) => (
    <FormControl variant="standard">
        <InputLabel htmlFor="helping-text-input">{t`contact-form-forms-detail-modal.form-field-section.helping-text`}</InputLabel>
        <Input
            value={props.helpingText.value}
            onChange={(event) => props.handleHelpingTextChange(event)}
            error={!(props.helpingText.errorMessage === "")}
            startAdornment={
                <InputAdornment position="start">
                    <AddCommentRounded color="action" />
                </InputAdornment>
            }
        />
    </FormControl>
))
