import { t } from "@lingui/macro"
import { makeAutoObservable } from "mobx"
import React from "react"

import {
    ConnectorAdminService,
    domain_FeatureConfig,
    FeaturesAdminService,
} from "src/api"
import { Channel } from "src/channel"
import { loads } from "src/channel/utils"
import { isLocalFile, persistFile } from "src/lib/file"
import { FormFields } from "src/lib/form-fields"
import { createLoadingKeys } from "src/lib/loading"
import { defaultValuesFeatures } from "src/modals/feature-configuration-detail/constants/defaultValuesForStore"
import { keyMap } from "src/modals/feature-configuration-detail/constants/keyMap"
import { IFeatureConfigurationDetailFormFields } from "src/modals/feature-configuration-detail/types/formFields"

import { ISessionLegalEntity } from "src/store/session"
import { IIntegrationConnectorItem } from "src/views/integration-connector/type"

export class FeatureConfigurationDetailStore {
    static Context =
        React.createContext<FeatureConfigurationDetailStore | null>(null)
    static LoadingKeys = createLoadingKeys("init", "submit")

    legalEntities: ISessionLegalEntity[] = []

    selectedLegalEntity: ISessionLegalEntity | null = null

    allIntegrationConnectors: IIntegrationConnectorItem[] = []

    form = new FormFields<IFeatureConfigurationDetailFormFields>({
        ...defaultValuesFeatures,
    })

    constructor() {
        makeAutoObservable(this)
    }

    //#region setters
    setLegalEntity(legalEntityId: number) {
        this.selectedLegalEntity =
            this.legalEntities.find((le) => le.id === legalEntityId) ?? null
    }

    //#endregion

    //#region getters
    get copyableLegalEntities() {
        return this.legalEntities.filter(
            (le) => le.id !== this.selectedLegalEntity?.id,
        )
    }
    //#endregion

    //#region store operations
    init(legalEntities: ISessionLegalEntity[]) {
        this.legalEntities = legalEntities
    }

    @loads(() => FeatureConfigurationDetailStore.LoadingKeys.submit)
    async validateAndSubmit() {
        const legalEntity = this.selectedLegalEntity
        if (legalEntity == null) {
            this.form.setError(
                "generic",
                t`feature-configuration-detail-modal.legal-entity-section.legal-entity-is-required`,
            )
            return
        }

        const featureConfig = await this.formFieldsToFeaturesConfigMap(
            this.form.data,
        )

        await FeaturesAdminService.putV1AdminFeaturesConfig({
            request: {
                feature_config: [
                    {
                        legal_entity_id: legalEntity.id,
                        feature_config: Object.fromEntries(
                            Object.entries(featureConfig).map(
                                ([name, value]) => [name, String(value)],
                            ),
                        ),
                    },
                ],
            },
        })

        Channel.send({
            name: "repository/updated",
            payload: {
                repository: "feature-configurations",
                action: "update",
                item: {
                    id: legalEntity.id,
                    name: legalEntity.legalName as string,
                },
            },
        })
    }

    @loads(() => FeatureConfigurationDetailStore.LoadingKeys.init)
    private async loadFeatures() {
        if (this.selectedLegalEntity == null) {
            return []
        }

        const response = await FeaturesAdminService.getV1AdminFeaturesConfig({
            legalEntityId: this.selectedLegalEntity.id,
        })

        return response.feature_config ?? []
    }

    private async getAllIntegrationConnectors(
        type: string = "balder_case",
    ): Promise<{ items: IIntegrationConnectorItem[] }> {
        const response =
            await ConnectorAdminService.getV1AdminConnectorIntegration({
                type,
            })
        const items: IIntegrationConnectorItem[] =
            response.map((item) => ({
                id: item.integration_id as number,
                accessGroupId: item.access_group_id as number,
                accessType: item.access_type as string,
                createdAt: item.created_at as string,
                deletedAt: item.deleted_at as string,
                integrationId: item.integration_id as number,
                integrationTypeId: item.integration_type_id as number,
                metaData: item.metadata as Record<string, string>,
                name: item.name as string,
                type: item.type as string,
                updatedAt: item.updated_at as string,
            })) ?? []

        return {
            items,
        }
    }
    //#endregion

    //#region helpers
    async loadConfig() {
        const features = await this.loadFeatures()
        this.form.init({
            ...this.featuresListToFormFields(features),
        })
        this.form.setAllFieldsAsDirty()
        await this.getIntegrationConnectors()
    }

    async getIntegrationConnectors() {
        const response = await this.getAllIntegrationConnectors()
        this.allIntegrationConnectors = [
            {
                id: -1,
                accessGroupId: -1,
                accessType: "",
                createdAt: "",
                deletedAt: "",
                integrationId: -1,
                integrationTypeId: -1,
                metaData: {},
                name: "- None -",
                type: "",
                updatedAt: "",
            },
            ...response.items,
        ]
    }

    private featuresListToFormFields(
        features: domain_FeatureConfig[],
    ): IFeatureConfigurationDetailFormFields {
        const featuresMap = features.reduce(
            (acc, { name, value }) => {
                if (name != null) {
                    const mappedName =
                        keyMap.toForm[name as keyof typeof keyMap.toForm]
                    if (mappedName != null) {
                        acc[mappedName] = value
                    }
                }
                return acc
            },
            {} as {
                [K in keyof IFeatureConfigurationDetailFormFields]?: string
            },
        )

        return {
            ...defaultValuesFeatures,
            ...featuresMap,
            terminationConnectorId: Number(featuresMap.terminationConnectorId),
            // These keys below contain files urls which will need to be
            // converted to ILocalFiles to be used with upload files.
            brandingBackgroundImage: this.fileUrlToPersistedFile(
                featuresMap["brandingBackgroundImage"],
            ),
            brandingLogo: this.fileUrlToPersistedFile(
                featuresMap["brandingLogo"],
            ),
            overviewHeaderImage: this.fileUrlToPersistedFile(
                featuresMap["overviewHeaderImage"],
            ),
            overviewLogo: this.fileUrlToPersistedFile(
                featuresMap["overviewLogo"],
            ),
            propertyOwnerEmailLogo: this.fileUrlToPersistedFile(
                featuresMap["propertyOwnerEmailLogo"],
            ),
            companyLogo: this.fileUrlToPersistedFile(
                featuresMap["companyLogo"],
            ),
            overviewAndAppIcon: this.fileUrlToPersistedFile(
                featuresMap["overviewAndAppIcon"],
            ),
            overviewHeaderImageCompact: this.fileUrlToPersistedFile(
                featuresMap["overviewHeaderImageCompact"],
            ),
            sidePaneImageExpanded: this.fileUrlToPersistedFile(
                featuresMap["sidePaneImageExpanded"],
            ),
            loginBackgroundCompact: this.fileUrlToPersistedFile(
                featuresMap["loginBackgroundCompact"],
            ),
            loginBackgroundExpanded: this.fileUrlToPersistedFile(
                featuresMap["loginBackgroundExpanded"],
            ),
        }
    }

    private async formFieldsToFeaturesConfigMap(
        formFields: IFeatureConfigurationDetailFormFields,
    ) {
        const keys = Object.keys(formFields)

        const entries = keys.map(async (k) => {
            const key =
                k as unknown as keyof IFeatureConfigurationDetailFormFields
            const nextValue = await this.transformToConfigMapValue(
                formFields[key],
            )
            return [keyMap.fromForm[key], nextValue] as const
        })

        return Object.fromEntries(await Promise.all(entries))
    }

    private fileUrlToPersistedFile(
        url: string | undefined,
    ): IPersistedFile | null {
        if (url == null) {
            return null
        }

        return { type: "image", name: url, url }
    }

    private async fileToPersistedUrl(file: IFile) {
        if (isLocalFile(file)) {
            try {
                const persistedFile = await persistFile(file, "image")
                return persistedFile.url
            } catch {
                return ""
            }
        } else {
            return file.url
        }
    }

    private transformToConfigMapValue(
        value: IFeatureConfigurationDetailFormFields[keyof IFeatureConfigurationDetailFormFields],
    ) {
        if (typeof value === "string") {
            return value
        } else if (value == null) {
            return ""
        } else if (typeof value === "number") {
            return value
        } else {
            return this.fileToPersistedUrl(value)
        }
    }
    //#endregion

    setTerminationConnectorId = (id: number) => {
        if (id === -1) {
            this.form.set("terminationConnectorId", "")
        } else {
            this.form.set("terminationConnectorId", id)
        }
    }
}
