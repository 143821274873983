import { Stack, Button, useTheme } from "@mui/material"
import { observer } from "mobx-react"

import { t } from "@lingui/macro"

import { useEffect, useState } from "react"

import { FormTypeDropdown } from "./FormTypeDropdown"

import { LabelQuestionTextField } from "./LabelQuestionTextField"

import { BottomContainerSection } from "./BottomContainerSection"

import { ExternalFieldTypeDropdown } from "./ExternalFieldTypeDropdown"

import { ExternalFieldRelationshipDropdown } from "./ExternalFieldRelationshipDropdown"

import { FormDetailStore } from "src/modals/contact-forms-form-builder/store"
import {
    CardContainer,
    Separator,
    CardInnerContainer,
    DraggableIconContainer,
} from "src/modals/contact-forms-form-builder/styled"

import { IExternalDynamicFieldsSectionProps } from "src/modals/contact-forms-form-builder/types/dynamicFieldSection"

import { FormTypeDropdownKeys } from "src/modals/contact-forms-form-builder/types/formTypeDropdownValues"

import { useWindowWide } from "src/views/feature-toggles/features/useWindowWide"
import { useStore } from "src/store/lib/useStore"
import { Add24 } from "src/components/icons/Add24"
import { Icon } from "src/components/icons"
import { DragIndicator } from "src/components/icons/DragIndicator"
import { isBinButtonDisabled } from "src/modals/contact-forms-form-builder/helper/isBinButtonDisabled"
import { ISourceProps } from "src/modals/contact-forms-form-builder/types/formFieldsSection"
import { AddDynamicOptionsSection } from "src/modals/contact-forms-form-builder/DynamicViewComponents/AddDynamicOptionsSection"
import { HelpingInputText } from "src/modals/contact-forms-form-builder/DynamicViewComponents/HelpingInputText"

export const ExternalDynamicFieldsSection = observer(
    (props: IExternalDynamicFieldsSectionProps) => {
        const store = useStore(FormDetailStore)
        const theme = useTheme()
        const [relationshipArray, setRelationshipArray] = useState<
            ISourceProps[]
        >([])
        const [isHelpTextVisible, setIsHelpTextVisible] = useState(false)
        const currentWidth = useWindowWide(0)
        const externalFieldsArray = store.formFields.get("externalFieldList")
        const noRelation = t`contact-form-forms-detail-modal.form-fields-settings-external-field-relationship-dropdown.default-value`

        useEffect(() => {
            const list = [...props.externalDynamicFieldsList]
            const array = list.map((item) => ({
                key: item.question.value,
                value: item.uuid,
            }))
            setRelationshipArray([
                { key: noRelation, value: noRelation },
                ...array,
            ])
            if (
                props.externalDynamicFieldsList[props.index].helpingText.value
                    .length > 0
            ) {
                setIsHelpTextVisible(true)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.externalDynamicFieldsList])

        const getFormTypeDropdownValue = (type: string) => {
            switch (type) {
                case "select":
                    return FormTypeDropdownKeys.Dropdown
                case "radio":
                    return FormTypeDropdownKeys.RadioButton
                case "textarea":
                    return FormTypeDropdownKeys.Paragraph
                case "checkbox":
                    return FormTypeDropdownKeys.Checkbox
                case "text":
                    return FormTypeDropdownKeys.ShortAnswer
                case "file":
                    return FormTypeDropdownKeys.FileUpload
            }
        }

        const onSelectExternalFieldTypeValueChange = (value: string) => {
            let data = [...props.externalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[props.index]))
            obj.externalFieldTypeDropdownValue.errorMessage = ""
            store.setHasError(false)
            obj.externalFieldTypeDropdownValue.value =
                value.trim() !== "" ? value : ""
            obj.fieldTypeDropdownValue.value = getFormTypeDropdownValue(
                externalFieldsArray?.find((item) => item.source === value)
                    ?.type ?? "",
            )
            obj.isAddOptionsVisible =
                externalFieldsArray?.find((item) => item.source === value)
                    ?.internal ?? false

            if (value.trim() === "") {
                store.setHasError(true)
                obj.externalFieldTypeDropdownValue.errorMessage = t`errors.required`
            }
            data[props.index] = obj
            props.setExternalDynamicFieldsList(data)
        }

        const onSelectRelationshipValueChange = (value: string) => {
            let data = [...props.externalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[props.index]))
            obj.relationshipDropdownValue.errorMessage = ""
            store.setHasError(false)
            obj.relationshipDropdownValue.value =
                value.trim() !== "" ? value : ""
            if (value.trim() === "") {
                store.setHasError(true)
                obj.question.errorMessage = t`errors.required`
            }
            data[props.index] = obj
            props.setExternalDynamicFieldsList(data)
        }

        const handleQuestionFormChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const list = [...props.externalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(list[props.index]))
            const value = event.target.value
            obj.question.errorMessage = ""
            store.setHasError(false)
            obj.question.value = value.trim() !== "" ? value : ""
            if (value.trim() === "") {
                store.setHasError(true)
                obj.question.errorMessage = t`errors.required`
            }
            list[props.index] = obj
            props.setExternalDynamicFieldsList(list)
        }

        const handleHelpingTextChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const list = [...props.externalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(list[props.index]))
            const value = event.target.value
            obj.helpingText.errorMessage = ""
            store.setHasError(false)
            obj.helpingText.value = value.trim() !== "" ? value : ""
            if (value.trim() === "") {
                store.setHasError(true)
                obj.helpingText.errorMessage = t`errors.required`
            }
            list[props.index] = obj
            props.setExternalDynamicFieldsList(list)
        }

        const onToggleHelpText = () => {
            setIsHelpTextVisible(!isHelpTextVisible)
            handleHelpingTextChange({
                target: { value: "" },
            } as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
            store.setHasError(false)
        }

        const onSelectValueChange = (value: string) => {
            let data = [...props.externalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(data[props.index]))
            obj.fieldTypeDropdownValue.errorMessage = ""
            store.setHasError(false)
            obj.fieldTypeDropdownValue.value = value.trim() !== "" ? value : ""
            if (value.trim() === "") {
                store.setHasError(true)
                obj.fieldTypeDropdownValue.errorMessage = t`errors.required`
            }
            data[props.index] = obj
            props.setExternalDynamicFieldsList(data)
        }
        const handleInputChange = (
            index: number,
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
            const list = [...props.externalDynamicFieldsList]
            const obj = JSON.parse(JSON.stringify(list[props.index]))
            const value = event.target.value
            obj.inputList[index].errorMessage = ""
            store.setHasError(false)
            obj.inputList[index].value = value.trim() !== "" ? value : ""
            if (value.trim() === "") {
                store.setHasError(true)
                obj.inputList[index].errorMessage = t`errors.required`
            }
            list[props.index] = obj
            props.setExternalDynamicFieldsList(list)
        }
        const getSourcesList = () => {
            return relationshipArray.filter(
                (item) =>
                    item.value !==
                    props.externalDynamicFieldsList[props.index].uuid,
            )
        }
        const handleRemoveClick = (index: number) => {
            store.setHasError(false)
            const list = [...props.externalDynamicFieldsList]
            list[props.index].inputList.splice(index, 1)
            props.setExternalDynamicFieldsList(list)
        }

        const handleAddClick = () => {
            const list = [...props.externalDynamicFieldsList]
            list[props.index].inputList = [
                ...list[props.index].inputList,
                { value: "", errorMessage: "" },
            ]
            props.setExternalDynamicFieldsList(list)
        }

        const shouldShowRelationshipDropdown = () =>
            props.externalDynamicFieldsList[props.index].fieldTypeDropdownValue
                .value === FormTypeDropdownKeys.Dropdown ||
            props.externalDynamicFieldsList[props.index].fieldTypeDropdownValue
                .value === FormTypeDropdownKeys.Checkbox ||
            props.externalDynamicFieldsList[props.index].fieldTypeDropdownValue
                .value === FormTypeDropdownKeys.RadioButton

        return (
            <CardContainer windowWidth={currentWidth}>
                <CardInnerContainer>
                    <DraggableIconContainer>
                        <DragIndicator />
                    </DraggableIconContainer>
                    <Stack spacing={2}>
                        <ExternalFieldTypeDropdown
                            sources={externalFieldsArray ?? []}
                            selectedValue={
                                props.externalDynamicFieldsList[props.index]
                                    .externalFieldTypeDropdownValue.value
                            }
                            onSelectionChange={
                                onSelectExternalFieldTypeValueChange
                            }
                            errorMessage={
                                props.externalDynamicFieldsList[props.index]
                                    .externalFieldTypeDropdownValue.errorMessage
                            }
                        />
                        {props.externalDynamicFieldsList[props.index]
                            .externalFieldTypeDropdownValue.value.length > 0 ? (
                            <>
                                <FormTypeDropdown
                                    index={props.index}
                                    item={
                                        props.externalDynamicFieldsList[
                                            props.index
                                        ].fieldTypeDropdownValue
                                    }
                                    onSelectValueChange={onSelectValueChange}
                                    disabled={
                                        !props.externalDynamicFieldsList[
                                            props.index
                                        ].isAddOptionsVisible
                                    }
                                    isVeticOtherSelected={
                                        props.externalDynamicFieldsList[
                                            props.index
                                        ].isAddOptionsVisible
                                    }
                                />
                                {shouldShowRelationshipDropdown() ? (
                                    <ExternalFieldRelationshipDropdown
                                        sources={getSourcesList()}
                                        selectedValue={
                                            props.externalDynamicFieldsList[
                                                props.index
                                            ].relationshipDropdownValue.value
                                        }
                                        onSelectionChange={
                                            onSelectRelationshipValueChange
                                        }
                                        errorMessage={
                                            props.externalDynamicFieldsList[
                                                props.index
                                            ].relationshipDropdownValue
                                                .errorMessage
                                        }
                                    />
                                ) : null}
                            </>
                        ) : null}

                        <LabelQuestionTextField
                            index={props.index}
                            item={
                                props.externalDynamicFieldsList[props.index]
                                    .question
                            }
                            handleFormChange={handleQuestionFormChange}
                        />
                        {props.externalDynamicFieldsList[props.index]
                            .fieldTypeDropdownValue.value ===
                            FormTypeDropdownKeys.Dropdown &&
                        props.externalDynamicFieldsList[props.index]
                            .isAddOptionsVisible ? (
                            <>
                                {props.inputList.map((input, index) => {
                                    return (
                                        <AddDynamicOptionsSection
                                            type={FormTypeDropdownKeys.Dropdown}
                                            index={index}
                                            item={input}
                                            handleInputChange={
                                                handleInputChange
                                            }
                                            handleRemoveClick={
                                                handleRemoveClick
                                            }
                                            disabled={store.isEditMode}
                                        />
                                    )
                                })}
                                <Button
                                    sx={{ alignSelf: "flex-start" }}
                                    startIcon={
                                        <Icon
                                            icon={<Add24 />}
                                            size={20}
                                            color={
                                                store.isEditMode
                                                    ? theme.palette.info.light
                                                    : theme.palette.info.main
                                            }
                                        />
                                    }
                                    disabled={store.isEditMode}
                                    color="info"
                                    onClick={handleAddClick}
                                >
                                    {t`contact-form-forms-detail-modal.form-field-section.add-options-label`}
                                </Button>
                            </>
                        ) : null}

                        {isHelpTextVisible && (
                            <HelpingInputText
                                helpingText={
                                    props.externalDynamicFieldsList[props.index]
                                        .helpingText
                                }
                                handleHelpingTextChange={
                                    handleHelpingTextChange
                                }
                            />
                        )}

                        <Separator />
                        <BottomContainerSection
                            variant="default"
                            index={props.index}
                            isRequired={props.isRequired ?? false}
                            handleSwitchChange={() =>
                                props.handleSwitchChange(props.index)
                            }
                            handleContentCopyClick={() =>
                                props.handleContentCopyClick(props.index)
                            }
                            handleDeleteClick={() =>
                                props.handleRemoveClick(props.index)
                            }
                            disableButton={isBinButtonDisabled(
                                props.externalDynamicFieldsList,
                            )}
                            handleHelpTextClick={onToggleHelpText}
                        />
                    </Stack>
                </CardInnerContainer>
            </CardContainer>
        )
    },
)
