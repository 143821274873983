import { memo } from "react"

export interface ITypingIndicatorProps {
    text: string
}

const TypingIndicator = memo((props: ITypingIndicatorProps) => {
    return (
        <div className="flex items-center space-x-2 p-4 rounded-lg bg-gray-100">
            <div className="text-gray-500">
                <div id="typing-indicator">
                    <span style={{ marginRight: "2px" }}>{props.text}</span>
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                </div>
            </div>
        </div>
    )
})

export default TypingIndicator
